import React, { Component } from "react";
import { Popup } from "semantic-ui-react";

export default class DonationTypes extends Component<any, any> {
  props: {
    count: number;
    icon: string;
    tooltip: string;
  };

  render() {
    const { count, icon, tooltip } = this.props;
    if (!count) {
      return <></>;
    }
    return (
      <Popup
        inverted
        basic
        style={{ backgroundColor: "#333333" }}
        position={"top center"}
        trigger={
          <div style={{ cursor: "pointer" }}>
            <img src={icon} alt="" />
            <span>{count}</span>
          </div>
        }
      >
        {tooltip}
      </Popup>
    );
  }
}
