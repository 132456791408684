import { ChoicesWithIconType } from "./common";

export type DonationType =
  | "blood"
  | "plasma"
  | "platelets"
  | "erythrocytes"
  | "leukocytes"
  | "unconfirmed_donations";

export type DonationTypes = {
  [s in DonationType]?: ChoicesWithIconType;
};

export const DONATION_TYPES: DonationTypes = {
  blood: {
    title: "Цельная кровь",
    icon: "/static/images/icons/blood.svg",
  },
  plasma: {
    title: "Плазма",
    icon: "/static/images/icons/plasma.svg",
  },
  platelets: {
    title: "Тромбоциты",
    icon: "/static/images/icons/trombs.svg",
  },
  erythrocytes: {
    title: "Эритроциты",
    icon: "/static/images/icons/erythrocytes.svg",
  },
  leukocytes: {
    title: "Гранулоциты",
    icon: "/static/images/icons/leukocytes.svg",
  },
  unconfirmed_donations: {
    title: "Неподтвержденные",
    icon: "/static/images/icons/unconfirmed_donations.svg",
  },
};

export const DONATION_TYPES_TOP: DonationTypes = {
  blood: {
    title: "Цельная кровь",
    icon: "/static/images/icons/blood.svg",
  },
  plasma: {
    title: "Плазма",
    icon: "/static/images/icons/plasma.svg",
  },
  platelets: {
    title: "Тромбоциты",
    icon: "/static/images/icons/trombs.svg",
  },
  erythrocytes: {
    title: "Эритроциты",
    icon: "/static/images/icons/erythrocytes.svg",
  },
  leukocytes: {
    title: "Гранулоциты",
    icon: "/static/images/icons/leukocytes.svg",
  },
};
