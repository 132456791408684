import React from "react";
import profileImg from "../../new-images/profile/profile.svg";
import Image from "next/image";
import globeImg from "../../new-images/globe.svg";
import styles from "./eventCard.module.scss"
import { TEventData, TUser } from "../../stores/EventStore";
import UserAvatar from "../ui/userAvatar";
import { useRouter } from "next/router"
import redirectTo from "../../utils/redirectTo";

const mounts = {
  1: "января",
  2: "февраля",
  3: "марта",
  4: "апреля",
  5: "мая",
  6: "июня",
  7: "июля",
  8: "августа",
  9: "сентября",
  10: "октября",
  11: "ноября",
  12: "декабря",
};

const MainEventCard:React.FC<{event: TEventData, currentUser?: any, past?: boolean}> = ({event, currentUser, past = false}) =>  {
  const router = useRouter();
  const {
    id,
    city,
    address,
    start_date,
    end_date,
    category,
    joined_users,
    joined_count,
    name,
    author,
    unity_partners,
    individual_partners,
    allPageClass,
    blood_station,
    type
  } = event;

  const startDate = id
    ? start_date?.split("").splice(0, 10).join("").split("-").reverse()
    : "";
  const endDate = id
    ? end_date?.split("").splice(0, 10).join("").split("-").reverse()
    : "";

  // Преобразовываю цифровое значение месяца в буквенное - хэш мап
  end_date &&
    (endDate[1] = mounts[Number(endDate[1])]) &&
    (startDate[1] = mounts[Number(startDate[1])]);
  const identicalDates = JSON.stringify(startDate) === JSON.stringify(endDate);
  const startTime = start_date?.split("").slice(11, 16).join("");
  const endTime = end_date?.split("").slice(11, 16).join("");

  // Проверка, записан ли юзер на мероприятие
  const checkDonor = currentUser && Object.values(currentUser?.joined_events)?.some((i) => i === id);

  return (
    <a
      className={styles.Event}
      href={`https://unity.donorsearch.org/events/${id}`}
    >
      <article className={styles.EventCard}>
        <div className={styles.EventCard__head}>
          {identicalDates ? (
            <div className={`${styles.HeadDate} ${styles.HeadOneDate}`}>
              <div>
                <span className={styles.HeadDate__number}>{startDate[0]} </span>
                <span className={styles.HeadDate__number}>{startDate[1]}</span>
                <span className={styles.HeadDate__number}> {startDate[2]}</span>
              </div>
              <div>
                <span className={styles.HeadDate__month}>{startTime}</span>
                <span className={styles.HeadDate__month}>{` - ${endTime}`}</span>
              </div>
            </div>
          ) : (
            <p className={styles.HeadDate}>
              <span className={styles.HeadDate__number}>{startDate[0]} </span>
              {(startDate[1] !== endDate[1] || startDate[2] !== endDate[2]) && (
                <span className={styles.HeadDate__number}>{startDate[1]} </span>
              )}
              {startDate[2] !== endDate[2] && (
                <span className={styles.HeadDate__number}>{startDate[2]}</span>
              )}
              <span className={styles.HeadDate__number}>{` - ${endDate[0]} `}</span>
              <span className={styles.HeadDate__number}>{endDate[1]} </span>
              <span className={styles.HeadDate__number}>{endDate[2]}</span>
            </p>
          )}
        </div>
        <div className={styles.EventCard__logoAndName}>
          <Image
            className={styles.EventCard__logo}
            src={author?.image || profileImg}
            loader={() => author?.image || profileImg}
            unoptimized={true}
            width={60}
            height={60}
            quality={50}
            alt="logo"
          ></Image>
          <h6 className={styles.EventCard__name}>{author?.name}</h6>
        </div>
        <h4 className={styles.EventCard__title} title={name}>
          {name}
        </h4>

        <div className={styles.EventCard__partners}>
          {(individual_partners?.length > 0 || unity_partners?.length > 0) && (
            <>
              <p className={styles.EventCard__partnersText}>Партнёры мероприятия</p>
              <div className={styles.HeadDate__images}>
                {individual_partners?.map((partner) => (
                  <Image
                    width={32}
                    height={32}
                    key={partner?.id}
                    className={styles.HeadDate__img}
                    alt={partner?.name}
                    src={partner?.image || profileImg}
                    loader={() => partner?.image || profileImg}
                    unoptimized={true}
                    quality={10}
                  ></Image>
                ))}
                {unity_partners?.map((partner) => (
                  <Image
                    width={32}
                    height={32}
                    key={partner?.id}
                    className={styles.HeadDate__img}
                    alt={partner?.name}
                    src={partner?.image || profileImg}
                    loader={() => partner?.image || profileImg}
                    unoptimized={true}
                    quality={10}
                  ></Image>
                ))}
              </div>
            </>
          )}
        </div>
        {category?.name?.toLowerCase() === "онлайн" ? (
            <div className={`${styles.TopContainer} ${styles.TopContainerFirst}`}>
              <Image
                className={styles.TopContainerImg}
                src={globeImg}
                width={15}
                height={14}
                alt="hospital"
              ></Image>
              <span className={styles.TopContainerText}>
                {category?.name}
              </span>
            </div>
        ) : category?.id === 1 ? (
          <p className={styles.EventCard__address}>
            {city?.title && `${city?.title}`}
            {blood_station?.address && `, ${blood_station?.address}`}
          </p>
        ) : (
          <p className={styles.EventCard__address}>
            {city?.title}
            {city?.title && address && ","} {address}
          </p>
        )}

        <div className={styles.EventCard__footer}>
          {type !== "past" ? (
            <button
              className={`${styles.EventCardFooterButton} ${
                checkDonor && styles.EventCardFooterButtonGray
              }`}
            >
              {checkDonor ? "Вы уже записаны" : "Записаться"}
            </button>
          ) : (
            <button className={`${styles.EventCardFooterButton} ${styles.EventCardFooterButtonGray} ${styles.EventCardFooterButtonGrayColor}`}>
              Мероприятие прошло
            </button>
          )}
          <div className={styles.VolunteersBlock}>
            {type === "past"
              ? joined_count > 0 &&
                // <p className={styles.VolunteersBlock__text}>Ходили</p>
                (joined_count > 2 ? (
                  <p className={styles.VolunteersBlock__text}>
                    {joined_users[0]?.user?.first_name}
                    {joined_count > 1 && ","}{" "}
                    {joined_users[1]?.user?.first_name} и ещё {joined_count - 2}{" "}
                    {joined_count === 3
                      ? "донор были там"
                      : joined_count > 3 && "донора были там"}
                  </p>
                ) : (
                  <p className={styles.VolunteersBlock__text}>
                    {`${joined_users[0]?.user?.first_name}${
                      joined_count > 1 ? "," : ""
                    } `}
                    {joined_users[1]?.user?.first_name}{" "}
                    {joined_count > 1 ? "были там" : "был там"}
                  </p>
                ))
              : joined_count > 0 &&
                (joined_count > 2 ? (
                  <p className={styles.VolunteersBlock__text}>
                    {joined_users[0]?.user?.first_name},{" "}
                    {joined_users[1]?.user?.first_name} и ещё {joined_count - 2}{" "}
                    {joined_count === 3 ? "донор идут" : "донора идут"}
                  </p>
                ) : (
                  <p className={styles.VolunteersBlock__text}>
                    {`${joined_users[0]?.user?.first_name}, `}
                    {joined_users[1]?.user?.first_name}{" "}
                    {joined_count > 1 ? "уже идут" : "уже идёт"}
                  </p>
                ))}

            <div className={styles.VolunteersAvatars}>
              {joined_count > 0 &&
                joined_users
                  .map((item) =>
                    item?.user?.photo ? (
                      <Image
                        className={styles.VolunteersBlock__avatar}
                        src={item?.user?.photo?.small}
                        alt="mini-avatar"
                        key={item.user.id}
                        loader={() => item?.user?.photo?.small}
                        unoptimized={true}
                        width={32}
                        height={32}
                        quality={50}
                      ></Image>
                    ) : (
                      <Image
                        className={styles.VolunteersBlock__avatar}
                        src={profileImg}
                        alt="mini-avatar"
                        key={item?.user?.id}
                        width={32}
                        height={32}
                      ></Image>
                    )
                  )
                  .slice(0, 3)}{" "}
              {joined_count > 3 ? (
                <div className={`${styles.VolunteersBlock__avatar} ${styles.EmptyAvatar}`}>
                  <span className={styles.AvatarBlackNumbers}>
                    +{joined_count - 3}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </article>
    </a>
  );
};

const LastUserCount = ({
  count,
  black = false,
  scale = 40,
}: {
  count: number;
  black: boolean;
  scale: number;
}) => {
  return (
    <div
      className={styles.LastUserCount}
      style={{ width: scale, height: scale }}
      data-black={black}
    >
      <span>+{count}</span>
    </div>
  );
};

export const Faces: React.FC<{
  joined_users: TUser[];
  scale?: number;
  display_count?: number;
  black?: boolean;
  total_joined: number;
}> = ({
  joined_users,
  scale = 40,
  display_count = 3,
  black = false,
  total_joined,
}) => {
  const totalUserCount = total_joined || 0;
  const lastUserCount = totalUserCount - display_count;

  return (
    <div className={styles.Faces}>
      {joined_users.slice(0, display_count).map((user) => (
        <UserAvatar user={user.user} scale={scale} key={user.user.id} />
      ))}
      {lastUserCount > 0 && (
        <LastUserCount black={black} scale={scale} count={lastUserCount} />
      )}
    </div>
  );
};


export default MainEventCard;

