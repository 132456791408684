import React, { Component } from "react";
import { Popup } from "semantic-ui-react";
import styles from "../../../styles/home.module.scss";

export default class MainDonationTypes extends Component<any, any> {
  props: {
    count: number;
    icon: string;
    tooltip: string;
    iconStyle?: React.CSSProperties;
  };

  render() {
    const { count, icon, tooltip, iconStyle } = this.props;
    // if (!count) {
    //   return null;
    // }
    return (
      <Popup
        inverted
        openOnTriggerClick={true}
        openOnTriggerMouseEnter={true}
        openOnTriggerFocus={true}
        basic
        style={{ backgroundColor: "#333333" }}
        position={"top center"}
        trigger={
          <div style={{ cursor: "pointer" }}>
            <div className="d-flex flex-column align-items-center">
              <img src={icon} alt="" className="d-block" style={iconStyle} />
              <span className={`d-block ${styles.BloodType_Text}`}>
                {count}
              </span>
            </div>
          </div>
        }
      >
        {tooltip}
      </Popup>
    );
  }
}
