import React, { useState } from "react";
import styles from "./topDonor.module.scss";
import { User } from "../../entities";
import UserAvatar from "../ui/userAvatar";
import UserBloodType from "../ui/userBloodType";
import UserDonorClass from "../ui/userDonorClass";
import { DONATION_TYPES } from "../../entities/profile_donation_types";
import { MainDonationTypes } from "../profileComponents/profileDonationTypes";
import { useStore, useStores } from "../../hooks/mobx";
import { useObserver, observer } from "mobx-react";
import { TOrganizationTeam } from "../../stores/OrganizationStore";

type TTopDonorCard = {
  info: User;
  type?: "default" | "horizontal" | "long" | "big_image" | "with_advice";
  notAgg?: boolean;
};

function useUiStore() {
  const { uiStore } = useStore(["uiStore"]);
  return useObserver(() => ({
    isMobile: uiStore.isMobile,
    uiStore,
  }));
}

const TopDonorCardWithAdvice = ({ info }) => {
  const [opened, setOpened] = useState(false);
  const { isMobile } = useUiStore();
  if (!info.text) return null;

  const maxSymbols = isMobile ? 150 : 250;

  const isOverflow = info.text.length >= maxSymbols;

  const onClick = () => {
    setOpened(!opened);
  };

  return (
    <div
      className={styles.TopDonor_WithAdvice}
      style={{ paddingBottom: isOverflow ? "16px" : "24px" }}
    >
      <div className={styles.TopDonor_WithAdvice_Container}>
        <div className={styles.TopDonor_WithAdvice_Container_User}>
          <div className={styles.TopDonor_WithAdvice_Container_User_Content}>
            <div
              className={styles.TopDonor_WithAdvice_Container_User_Content_Info}
            >
              <UserAvatar
                user={info?.user}
                style={{ marginBottom: "4px" }}
                scale={60}
              />
              <UserBloodType user={info?.user} />
            </div>
            <div
              className={styles.TopDonor_WithAdvice_Container_User_Content_Data}
            >
              <div
                className={
                  styles.TopDonor_WithAdvice_Container_User_Content_Data_Name
                }
              >
                {info?.user?.first_name || <br />}
                <br />
                {info?.user?.last_name || <br />}
              </div>
              <div
                className={
                  styles.TopDonor_WithAdvice_Container_User_Content_Data_City
                }
              >
                {info?.user?.city?.title || <br />}
              </div>
            </div>
          </div>
          <div className={styles.TopDonor_WithAdvice_Container_User_Info}>
            <UserDonorClass user={info?.user} />
            <div
              className={
                styles.TopDonor_WithAdvice_Container_User_Info_Donations
              }
            >
              донации <span>{info?.user?.donation_agg?.count || 0}</span>
            </div>
            <div
              className={
                styles.TopDonor_WithAdvice_Container_User_Info_BloodTypes
              }
            >
              {Object.keys(DONATION_TYPES).map((key) => {
                if (info?.user?.donation_agg)
                  if (info?.user?.donation_agg[key])
                    return (
                      <div className={styles.TopDonor_BloodType} key={key}>
                        <MainDonationTypes
                          tooltip={DONATION_TYPES[key]?.title}
                          icon={DONATION_TYPES[key]?.icon}
                          count={info?.user?.donation_agg[key]}
                          iconStyle={{ width: "14px", height: "14px" }}
                        />
                      </div>
                    );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.TopDonor_WithAdvice_Advice}>
        <div
          className={
            opened
              ? styles.TopDonor_WithAdvice_Advice_Text_Opened
              : styles.TopDonor_WithAdvice_Advice_Text_Closed
          }
        >
          {info.text}
        </div>
        {isOverflow && (
          <div className={styles.TopDonor_WithAdvice_Advice_Overflow}>
            {!opened && (
              <div
                className={styles.TopDonor_WithAdvice_Advice_Overflow_Smooth}
              />
            )}
            <div
              className={styles.TopDonor_WithAdvice_Advice_Overflow_Button}
              onClick={onClick}
            >
              {opened ? "Свернуть" : "Развернуть"}{" "}
              <div
                className={
                  styles.TopDonor_WithAdvice_Advice_Overflow_Button_Arrow
                }
                data-reversed={opened ? "true" : "false"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    d="M13.5 6L8.5 11L3.5 6"
                    stroke="#131416"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DefaultTopDonorCard = observer(({ info, notAgg = false }) => {
  const { authStore } = useStores();
  const { user } = authStore;
  const city = () => {
    if (info?.city?.title) {
      if (info?.city?.title?.length > 11) {
        return info?.city?.title?.slice(0, 11) + "...";
      } else {
        return info?.city?.title;
      }
    } else {
      return <br />;
    }
  };
  return (
    <div
      className={styles.TopDonor_DefaultCard}
      data-me={info?.id === user?.id}
    >
      <div className={styles.TopDonor_DefaultCard_Content}>
        <div className={styles.TopDonor_DefaultCard_Content_Info}>
          <UserAvatar user={info} style={{ marginBottom: "4px" }} />
          <UserBloodType user={info} />
        </div>
        <div className={styles.TopDonor_DefaultCard_Content_Data}>
          <div className={styles.TopDonor_DefaultCard_Content_Data_Name}>
            {info?.first_name || <br />}
            <br />
            {info?.last_name || <br />}
          </div>
          <div className={styles.TopDonor_DefaultCard_Content_Data_City}>
            {city()}
          </div>
        </div>
      </div>
      <div className={styles.TopDonor_DefaultCard_Info}>
        <UserDonorClass user={info} />
        <div className={styles.TopDonor_DefaultCard_Info_Donations}>
          донации{" "}
          <span>{notAgg ? info?.count_bs : info?.donation_agg?.count}</span>
        </div>
        {!notAgg && (
          <div className={styles.TopDonor_DefaultCard_Info_BloodTypes}>
            {Object.keys(DONATION_TYPES).map((key) => {
              if (info?.donation_agg)
                if (info?.donation_agg[key])
                  return (
                    <div className={styles.TopDonor_BloodType} key={key}>
                      <MainDonationTypes
                        tooltip={DONATION_TYPES[key]?.title}
                        icon={DONATION_TYPES[key]?.icon}
                        count={info?.donation_agg[key]}
                        iconStyle={{ width: "14px", height: "14px" }}
                      />
                    </div>
                  );
            })}
          </div>
        )}
        {notAgg && (
          <div className={styles.TopDonor_DefaultCard_Info_BloodTypes}>
            {Object.keys(DONATION_TYPES).map((key) => {
              if (info[`count_${key}`])
                return (
                  <div className={styles.TopDonor_BloodType} key={key}>
                    <MainDonationTypes
                      tooltip={DONATION_TYPES[key]?.title}
                      icon={DONATION_TYPES[key]?.icon}
                      count={info[`count_${key}`]}
                      iconStyle={{ width: "14px", height: "14px" }}
                    />
                  </div>
                );
            })}
          </div>
        )}
      </div>
    </div>
  );
});

export const TeamCard: React.FC<{ data: TOrganizationTeam }> = ({ data }) => {
  if (!data) return null;

  return (
    <div className={styles.TeamCard}>
      <div className={styles.TeamCard_Container}>
        <div className={styles.TeamCard_Container_Top}>
          {data?.image && (
            <div className={styles.TeamCard_Container_Top_Image}>
              <img src={data.image} alt={data.name} />
            </div>
          )}
          <div className={styles.TeamCard_Container_Top_Name}>
            <span>{data.name}</span>
          </div>
        </div>
        <div className={styles.TeamCard_Container_Bot}>
          <span>{data.position}</span>
        </div>
      </div>
    </div>
  );
};

const TopDonorCard = ({ info, type, notAgg }: TTopDonorCard) => {
  if (!info) return null;
  switch (type) {
    case "default":
      return <DefaultTopDonorCard info={info} />;
    case "with_advice":
      return <TopDonorCardWithAdvice info={info} />;

    default:
      return <DefaultTopDonorCard info={info} notAgg={notAgg} />;
  }
};

export default TopDonorCard;
